#offer-landing-pages {
  @include flexbox(column, wrap, flex-start);

  .landing-options {
    @include flexbox(row, nowrap, space-between);

    .landing-types {
      @include flexbox(row, wrap, flex-start);
    }

    .preview-landing-page {
      @include flexbox(row, nowrap, space-between);

      align-self: center;

      span {
        margin: 2px 0 0 5px;
      }
    }
  }

  #screenshot-wrapper {
    position: relative;

    .optimized-background {
      width: 100%;
      height: auto;
      display: block;
    }

    .optimized-small {
      position: absolute;
      z-index: 2;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

      &.mobile {
        border-radius: 5px 5px 0 0;
        max-width: unset;
        min-width: unset;
        max-height: unset;
      }
    }
  }

  #screenshot-loading {
    display: flex;

    img {
      margin: 0 auto;
      max-width: 200px;
    }
  }

  select:not(.native) {
    display: none;
  }

  .btn-select-landing {
    height: 3.5rem;
    line-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #screenshot-picture {
    align-self: center;
    width: 100%;
  }

  .landing-details {
    width: 100%;
    display: flex;
    flex-flow: column;

    @media (max-width: 992px) {
      margin-left: 0;
    }

    .featured_container {
      display: flex;
      align-items: center;
      .featured {
        margin-right: 10px;
        padding: 0;
        height: 30px;
      }
    }

    .featured {
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: auto;
    }

    p,
    span {
      text-align: start;
    }

    .btn {
      width: 100%;
      text-align: center;
    }

    .landing-name,
    .epc {
      border-bottom: 2px solid #cacaca;
      padding: 5px 0;
      display: flex;
      gap: 7px;

      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .isAutoOptimized {
      border-bottom: 0;
    }

    .preview-landing-page {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      border: none;
      padding-top: 5px;
      padding-bottom: 5px;

      a {
        display: flex;
        width: auto;

        &:hover {
          opacity: 0.75;
        }
      }

      span {
        margin: 2px 0 0 5px;
      }
    }

    .landing-name {
      font-size: 20px;
      font-weight: 900;
    }
  }

  .landing-box {
    width: 100%;

    .mobile {
      max-height: 320px;
      overflow: hidden;
      margin: 0 auto;
      width: 55%;
      min-width: 300px;
      max-width: 320px;

      &.optimized {
        max-height: none;
        max-width: none;
        width: 100%;

        &::before {
          display: none;
        }
      }

      &::before {
        display: block;
        position: absolute;
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
        margin-top: 255px;
        height: 65px;
        width: 100%;
        content: '';
      }
    }
  }
}

.offer-landing-page-modal {
  width: 55%;
  overflow: visible;
  border-radius: 10px;

  @media (max-width: 1455px) {
    width: 95%;
  }

  .modal-inner {
    background: #fff;
    border-radius: 10px;
  }

  .hidden {
    visibility: hidden;
  }
}
